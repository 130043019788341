<template>
    <AppPaginatedGridSesmt
        ref="grid"
        :service="service"
        newPath="/questionarios-sesmt/new"
        editPath="/questionarios-sesmt/"
        :expander="false"
        subtitle="Questionário SESMT"
        :actionItems="actionItems"
        @onToggleMenu="onToggleMenu"
        tooltip="Cadastro Questionário SESMT"
        permission="gestaosesmt_cadastros_questionario_sesmt"
        :tipo="tipoFiltroEnum.SISTEMA"
        :modulo="moduloFiltroEnum.QUESTIONARIO_SESMT"
        descricao="FILTRO PADRÃO QUESTIONÁRIOS SESMT"
        nomeTelaDoManual="questionarios-sesmt-list"
        :botaoVoltar="false"
        :showButtonLimparFiltros="false"
        labelBotaoAdd="Novo Questionario"
    >
        <template #columns>
            <Column field="id" header="Cód." :sortable="true" sortField="questionario_sesmt.id"></Column>
            <Column field="nome" header="Questionário" :sortable="true" sortField="questionario_sesmt.nome"></Column>
            <Column field="tipo" header="Tipo" :sortable="true" sortField="questionario_sesmt.tipo">
                <template #body="slotProps">
                    {{
                        slotProps.data.tipo === 'IF'
                            ? 'Identificação e caracterização de perigo/fator de risco'
                            : slotProps.data.tipo === 'AM'
                            ? 'Ambiente'
                            : slotProps.data.tipo === 'IN'
                            ? 'Inicial'
                            : ''
                    }}
                </template>
            </Column>
            <Column field="" header="Cliente">
                <template #body="slotProps">
                    {{
                        slotProps.data.todosClientesMarcados
                            ? 'Todos'
                            : questionarioSESMTCliente
                                  ?.filter((qsc) => qsc.idQuestionarioSESMT === slotProps.data.id)
                                  .map((qc) => {
                                      return ` ${qc?.cliente?.name}`;
                                  })
                                  .toString()
                                  .replaceAll(',', ';')
                    }}
                </template>
            </Column>
            <Column field="createdAt" :sortable="true" sortField="questionario_sesmt.createdAt" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps.data?.userCreated?.name }} </small>
                </template>
            </Column>

            <Column field="updatedAt" :sortable="true" sortField="questionario_sesmt.updatedAt" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data?.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps.data?.userUpdated?.name }} </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGridSesmt>
    <AppDeleteDialog
        v-if="showDeleteRecordDialog"
        v-model:visible="showDeleteRecordDialog"
        :record="record"
        @onConfirm="onClickDeleteRecord"
        @onClose="onClickCloseDeleteRecord"
    >
    </AppDeleteDialog>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import ModuloFiltroEnum from '../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../enums/TipoFiltroEnum';
import { getClientSesmt } from '../../services/http-sesmt';

export default {
    data() {
        return {
            service: null,
            tenantCustomers: [],
            questionarioSESMTCliente: [],
            record: {},
            showDeleteRecordDialog: false,
            permission: 'gestaosesmt_cadastros_questionario_sesmt',
            actionItems: [
                {
                    label: 'Alterar',
                    disabled: () => !this.$checkPermission(this.permission + ':editar') && this.permission,
                    icon: 'pi pi-pencil',
                    command: () => this.$refs.grid.editRecord(this.record)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    disabled: () => !this.$checkPermission(this.permission + ':excluir') && this.permission,
                    command: () => this.confirmDeleteRecord()
                },
                {
                    label: 'Copiar',
                    icon: 'pi pi-copy',
                    disabled: () => !this.$checkPermission(this.permission + ':criar') && this.permission,
                    command: () => this.$router.push(`/questionarios-sesmt/new?id=${this.record.id}`)
                }
            ]
        };
    },
    async mounted() {
        const response = await getClientSesmt().get(`/questionario-sesmt-cliente`);
        this.questionarioSESMTCliente = response.data;
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        },
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        },
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new SesmtService('/questionario-sesmt');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        onToggleMenu(event, data) {
            this.record = data;
        },
        async onClickDeleteRecord(record) {
            if (!record) {
                return;
            }

            try {
                const { data } = await this.service.remove(record.id);
                this.load();
                this.showDeleteRecordDialog = false;
                this.$toast.add({
                    severity: 'success',
                    summary: data,
                    life: 3000
                });
                await this.$emit('onAfterDelete');
            } catch (err) {
                const message = err?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao excluir registro! ' + message,
                    life: 5000
                });
            }
        },
        onClickCloseDeleteRecord() {
            this.showDeleteRecordDialog = false;
        },
        openDeleteRecordDialog() {
            this.showDeleteRecordDialog = true;
        },
        confirmDeleteRecord() {
            this.openDeleteRecordDialog();
        }
    }
};
</script>
